import React from 'react';
import Structure from '../components/structure/Structure';
import FileViewer from './FileViewer';
import Header from '../components/Header';

function DesktopDrive(props) {
  const { fstruct, selected_file } = { ...props }  

  return (
    <div className="drive-wrapper">
      <div className="row">
        {/* Left opthions sidebar */}
        <div className="col-xl-3 col-lg-4">
          <div className="card card-default">
            <div className="card-body">
              <Header></Header>

              <div className="folder-section">
                <h4>Folders</h4>
                <Structure fstruct={fstruct}></Structure>
              </div>

              <div className="options-section">
                <h4>Options</h4>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <i className="fas fa-fw fa-lg fa-arrow-circle-down"></i>
                  Download this Document
                </li>
                  <li className="list-group-item">
                    <i className="fas fa-fw fa-lg fa-arrow-circle-up"></i>
                  Upload a Document
                  {/* <input type="file" accept={".jpg,.jpeg,.png,.pdf"} onChange={this.handleChangFile} /> */}
                  </li>
                  <li className="list-group-item">
                    <i className="fas fa-fw fa-lg fa-search"></i>
                  Search Drive
                </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* View file */}
        <div className="col-xl-9 col-lg-8">
          <div className="card card-default">
            <div className="card-body">
              <FileViewer fileData={selected_file}></FileViewer>
            </div>
          </div>
        </div>

      </div>
    </div>
  )

}

export default DesktopDrive;
