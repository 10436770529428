import React from 'react';
import Viewer, { defaultLayout, SpecialZoomLevel } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

function PdfViewer(props) {
  const { file_url, file } = { ...props }

  const handleDownload = () => {
    window.open(file_url, "_blank");
  }

  const renderToolbar = (toolbarSlot) => {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            flexShrink: 1,
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.searchPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomOutButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomInButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              <div onClick={() => handleDownload()}>
                <button className="btn-download-pdf" >
                  <svg width="21" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z" fill="#596671" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const layout = (
    isSidebarOpened,
    container,
    main,
    toolbar,
    sidebar,
  ) => {
    return defaultLayout(
      isSidebarOpened,
      container,
      main,
      toolbar(renderToolbar),
      sidebar,
    );
  };

  const renderError = (error) => {
    let message = '';
    console.log("PDF ERROR - ", error);
    switch (error.name) {
      case 'InvalidPDFException':
        message = 'The document is invalid or corrupted';
        break;
      case 'MissingPDFException':
        message = 'The document is missing';
        break;
      case 'UnexpectedResponseException':
        message = 'Access denied, link has expired. Try refresh page.';
        break;
      default:
        message = 'Cannot load the document';
        break;
    }

    return (
      <div
        style={{
          alignItems: 'center',
          border: '1px solid rgba(0, 0, 0, 0.3)',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: '#e53e3e',
            borderRadius: '0.25rem',
            color: '#fff',
            padding: '0.5rem',
          }}
        >
          {message}
        </div>
      </div>
    );
  };

  return (
    <div className="pdf-viewer" style={{ height: '750px' }} >
      {
        file_url
          ? <Viewer fileUrl={file_url}
            defaultScale={SpecialZoomLevel.PageWidth}
            layout={layout}
            renderError={renderError} />
          : (
            <div
              style={{
                alignItems: 'center',
                border: '2px dashed rgba(0, 0, 0, .3)',
                display: 'flex',
                fontSize: '2rem',
                height: '100%',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              Preview area
            </div>
          )
      }
    </div>
  );
}

export default PdfViewer;
