export const PAYMENTREDUCER_PAYLOAD = 'paymentreducerpayload';
export const PAYMENTMEDIAREDUCER_PAYLOAD = 'paymentmediareducerpayload';
export const LOGINWITHNIC_PAYLOAD = "loginwithnicpayload";
export const OTP_PAYLOAD = "otppayload";
export const CREATE_USERPROFILE = "createuserprofile";
export const GETLOANBREAKDETAILS = "GETLOANBREAKDETAILS";

export const graybutton_background = '#5b6770';
export const blue_background = '#164e99';


export const GREEN = '#8BA73D';
export const PURPLE_COLOR = '#001f72';
export const BLUE_COLOR = '#30cdff';
export const WHITE_COLOR = '#ffffff';
export const SPECIAL_BLUE = '#00a3e0';
export const LIGHT_BLUE = '#CBECF9';
export const SPECIAL_RED = '#ed1c24';
export const AZURE_BLUE = '#219bfd';
export const CANARY_YELLOW = '#E68722';
export const LIGHTCANARY_YELLOW = '#f3a536';
export const SPECIAL_GRAY = '#b5b5b5';

export const DISABLED_BACKGROUND_BUTTON = '#d4d4d4';
export const DISABLED_BUTTON_TEXT = '#818181';

export const isValid_COLORCODE = "#8ba732";
export const isExpiring_COLORCODE = "#f15922";
export const isExpired_COLORCODE = "#821515";

export const datacolor_Health = "#34c3d4";
export const datacolor_Car = "#821515";
export const datacolor_Home = "#0065b3";
export const datacolor_Travel = "#009cdb";
export const datacolor_Boat = "#2a8ca0";
export const datacolor_Others = "#ea216a";
export const datacolor_Life = "#006950";
export const lightGray = "rgba(106, 116, 123, 0.3)";



