import {combineReducers} from 'redux';
import Authreducer from "./Authreducer";
import SnackbarReducer from "./SnackbarReducer";
import FolderStructureReducer from "./FolderStructureReducer";
import FilesReducer from "./FilesReducer";
export default combineReducers({
    authreducer:Authreducer,
    snackbarreducer: SnackbarReducer,
    structure_reducer: FolderStructureReducer,
    file_reducer: FilesReducer
})
