import React from 'react';
import Moment from 'moment';
import styles from './fileslist.module.css';


function MobileFilesList(props) {
  const { files, selected_file, changeFile } = { ...props }
  let fileItem;
  const getFileIcon = (type) => {
    if (type === "application/pdf") {
      return <i className="far fa-2x fa-file-pdf"></i>
    }
    else {
      return <i className="far fa-2x fa-file-image"></i>
    }
  }
  // const getFileViewed = (file) => {
  //   if (!file.viewed) {
  //     return <div className="need-view-file"></div>
  //   }
  //   else {
  //     return null
  //   }
  // }
  
  // console.log("FILES - ", files);
  if (files && files.length) {
    fileItem = files.map((file, index) => {
      let isActive = false;
      if (selected_file) {
        if (selected_file.uuid === file.uuid) {
          isActive = true;
        }
      }
      return (
        <div key={file.uuid} className="col col-6 col-sm-4 col-md-2">
          <div className={styles.file_item} onClick={() => changeFile(file)}>
            <div className={styles.file_icon}>
              {/* {getFileViewed(file)} */}
              {getFileIcon(file.metadata.content_type)}
            </div>
            <div className={styles.file_content}>
              <p className={styles.title} style={{ fontWeight: !file.viewed ? 'bold' : '' }}>{file.metadata.filename}</p>
              <p className={styles.created_at}>Issued: {Moment(file.created_at).format("DD.MM.YYYY")}</p>
            </div>

          </div>
        </div>
      )
    })
  }
  else {
    fileItem = <div className={`col ${styles.empty_content}`}>No documents available</div>
  }

  return (
    fileItem
  )
}

export default MobileFilesList;
