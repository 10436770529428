import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginwithNic, validateOTP, GetLoanBreakDtls, GetKycProfileData, verifyKey, getSignToken } from '../actions';
import { getFolderStructure } from '../actions/folderStructureAction';
import { showSnackbar } from '../actions/snackbarActions';
import {
  Redirect
} from "react-router-dom";
import { Loader } from '../components';
import Header from '../components/Header';
const NEEDHELP_MSG = "Call our hotline on +230 207 9111 between 9.30am to 06.30pm Monday to Friday and Saturday between 9.30am to 5 pm or else email us on myswan.appsupport@swanforlife.com";

class Login extends Component {

  state = {
    nationalid: "",
    otpvalue: "",
    nicvalid: false,
    authenticated: false,
    loading: false,
    signup: false,
    otpvalid: false,
    token: ""
  }

  componentDidMount() {

  }

  showLoading() {
    this.setState({ loading: true })
  }

  hideLoading() {
    this.setState({ loading: false })
  }

  continuePressed() {
    if (this.state.nationalid == "") {
      this.props.showSnackbar("Please enter a valid NIC")
    } else {
      //Fine go ahead
      this.showLoading();
      this.props.loginwithNic(this.state.nationalid)
    }
  }

  otpcontinuePressed() {
    if (this.state.otpvalue == "") {
      this.props.showSnackbar("Please enter a valid OTP")
    } else {
      //Fine go ahead
      this.showLoading();
      this.props.validateOTP(this.state.nationalid, this.state.otpvalue);
    }

  }

  _handleKeyDown(e, fieldname) {
    if (e.key === 'Enter' && fieldname == "nicfield") { this.continuePressed(); }
    if (e.key === 'Enter' && fieldname == "otpfield") { this.otpcontinuePressed(); }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.hideLoading();
    if (nextProps.authreducer.nic) {
      const comparator_nic = nextProps.authreducer.nic.response;

      if (comparator_nic == "VALID") {
        this.setState({ nicvalid: true });
      } else if (comparator_nic == "INVALID") {
        this.props.showSnackbar("NIC is invalid");
      } else if (comparator_nic == "LOCKED") {
        this.props.showSnackbar("This account is locked. Kindly contact SWAN");
      } else if (comparator_nic == "NOCONTACT") {
        this.props.showSnackbar('We do not have your mobile number/email address. Please call our hotline on +230 207 9111 between 9.30am to 06.30pm Monday to Friday and Saturday between 9.30am to 5 pm or else email us on myswan.appsupport@swanforlife.com')
      } else if (comparator_nic == "NOTFOUND") {
        this.props.showSnackbar("We do not have your details. Please call our hotline on +230 207 9111 between 9.30am to 06.30pm Monday to Friday and Saturday between 9.30am to 5 pm or else email us on myswan.appsupport@swanforlife.com!");
      } else {
        this.props.showSnackbar("NIC Invalid");
      }
    }

    if (nextProps.authreducer.otp) {
      const comparator_nic = nextProps.authreducer.otp.response;
      if (comparator_nic == "VALID") {
        this.props.getSignToken(this.state.nationalid.replace("-TEST", ""));
        // this.props.getFolderStructure(this.state.nationalid.replace("-TEST", ""));

        this.setState({ otpvalid: true });
      } else {
        this.setState({ nicvalid: false });
        this.props.showSnackbar("OTP Incorrect");
      }
    }
    if (nextProps.authreducer.verify_token) {
      const response = nextProps.authreducer.verify_token.response;
      if (response == "VALID") {
        // this.props.GetLoanBreakDtls(this.state.nationalid.replace("-TEST", ""));
        // this.props.GetKycProfileData(this.state.nationalid.replace("-TEST", ""));
        // this.props.getFolderStructure(nextProps.authreducer.verify_token.nic, nextProps.authreducer.verify_token.token);

        this.setState({ authenticated: true, token: nextProps.authreducer.verify_token.token });
      }
    }
  }//END componentWillReceiveProps()


  render() {
    return (
      <div className="container-fluid">
        {
          this.state.loading &&
          <Loader></Loader>
        }

        <Header></Header>
        <div className="signup-wrapper">
          {
            this.state.authenticated && this.state.token !== "" &&
            <Redirect
              to={{
                pathname: "/drive",
                search: "?access_token=" + this.state.token,
              }}
            />
          }

          <div className="signup-form">
            {!this.state.nicvalid && <div>
              <p className="slogan">
                <span className="small">If you already have a mySWAN Account please enter your National ID below</span>
              </p>

              <div className="form-group">
                <input className="form-control" id='nicfield' type="text"
                  onKeyDown={(e) => this._handleKeyDown(e, "nicfield")}
                  value={this.state.nationalid}
                  onChange={(e) => this.setState({ nationalid: e.target.value })}
                  placeholder="Enter National ID" />
              </div>

              <div className="buttons-wrapper">
                <button className="btn button register-button"
                  onClick={() => this.continuePressed()}>Continue
                  </button>

                <button className="btn forgot-button"
                  onClick={() => this.props.showSnackbar(NEEDHELP_MSG)}>Need Help?
                </button>
              </div>
            </div>}

            {this.state.nicvalid && <div>
              <p className="slogan">
                <span className="small">Please send the OTP sent to your phone number</span>
              </p>

              <div className="form-group">
                <input className="form-control" id='otpfield' type="text"
                  onKeyDown={(e) => this._handleKeyDown(e, "otpfield")}
                  value={this.state.otpvalue}
                  onChange={(e) => this.setState({ otpvalue: e.target.value })}
                  placeholder="Enter OTP" />
              </div>

              <div className="buttons-wrapper">
                <button className="btn register-button"
                  onClick={() => this.otpcontinuePressed()}>Continue
                </button>
                <button className="btn forgot-button"
                  onClick={() => this.props.showSnackbar(NEEDHELP_MSG)}>Need Help?
                </button>
              </div>
            </div>}
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (data) => {
  const { authreducer } = (data)
  return { authreducer };
}

export default connect(mapStateToProps, { loginwithNic, validateOTP, GetLoanBreakDtls, GetKycProfileData, verifyKey, showSnackbar, getFolderStructure, getSignToken })(Login);

