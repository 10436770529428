import React, { useState, useEffect } from 'react';
import PdfViewer from '../components/viewer/PdfViewer';
import ImageViewer from '../components/viewer/ImageViewer';
import { Worker } from '@phuocng/react-pdf-viewer';
import { useStore } from 'react-redux';
import { getFileLink } from '../actions/folderStructureAction';

const getToken = (state) => {
  if (state.authreducer.verify_token) {
    return state.authreducer.verify_token.token;
  }
  return null;
}

function FileViewer(props) {
  const { fileData } = { ...props }
  const [link, setLink] = useState('');
  const store = useStore();
  const state = store.getState();
  const token = getToken(state);
  useEffect(() => {
    async function fetchData() {
      try {
        let link_url = await getFileLink(fileData.uuid, token);
        setLink(link_url);
      }
      catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  const renderContent = () => {
    if (fileData && link) {
      if (fileData.metadata.content_type === "application/pdf") {
        return (
          <Worker workerUrl="/assets/libs/pdf.worker2.16.105.min.js">
            <PdfViewer file={fileData.metadata} file_url={link}></PdfViewer>
          </Worker>
        )
      }
      else {
        return <ImageViewer file_url={link}></ImageViewer>
      }

    }
    else {
      return null
    }
  }

  return (
    <div className="viewer-wrapper" >
      {renderContent()}
    </div>
  )

}

export default FileViewer;
