import React from 'react';

function ImageViewer(props) {
  const { file_url } = { ...props }

  return (
    <div className="image-viewer">
      <img src={file_url} style={{ width: '100%', height: '100%' }} />
    </div>
  );
}

export default ImageViewer;
