import React, { Component } from 'react';
import FolderStructure from './FolderStructure';
import '../../css/structure.css';
import { setFileSelected } from '../../actions/folderStructureAction';
import { connect } from 'react-redux';

const fstruct = [
  {
    "doctype": "BNSLTR",
    "fld": [
      {
        "fldname": "2020",
        "fld": [
          {
            "fldname": "Swan",
            "files": [
              {
                "uuid": "40105ef0-b131-11ea-aa91-f77318a17d37",
                "download_url": "https://swan-kyc.s3.us-east-2.amazonaws.com/BNSLTR/18-01-81/A180181300165G/40105ef0-b131-11ea-aa91-f77318a17d37?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXIIN6KSJOFJ4YTNE%2F20200626%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20200626T143210Z&X-Amz-Expires=900&X-Amz-Signature=3952c7a513e259b04cfdf7b041dbed58a3d427a52b7716f5a1877de451eea117&X-Amz-SignedHeaders=host",
                "doctype": "BNSLTR",
                "owner_role": "swan",
                "content_type": "application/pdf",
                "filename": "loansdept",
                "path": "2020/Swan",
                "tags": [
                  "address",
                  "correspondence"
                ],
                "nic": "A180181300165G"
              },
              {
                "uuid": "e0123500-b149-11ea-bff6-87a4d98afa7b",
                "download_url": "https://swan-kyc.s3.us-east-2.amazonaws.com/BNSLTR/18-01-81/A180181300165G/e0123500-b149-11ea-bff6-87a4d98afa7b?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXIIN6KSJOFJ4YTNE%2F20200626%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20200626T143210Z&X-Amz-Expires=900&X-Amz-Signature=9ab75581b17fc40b0ff1625f25e8c0c35406f8695ec83cdc463979e7948da582&X-Amz-SignedHeaders=host",
                "doctype": "BNSLTR",
                "owner_role": "swan",
                "content_type": "application/pdf",
                "filename": "loansdept",
                "path": "2020/Swan",
                "tags": [
                  "address",
                  "correspondence"
                ],
                "nic": "A180181300165G"
              },
              {
                "uuid": "c5bb0b50-b257-11ea-bb61-b3c1e071d10d",
                "download_url": "https://swan-kyc.s3.us-east-2.amazonaws.com/BNSLTR/18-01-81/A180181300165G/c5bb0b50-b257-11ea-bb61-b3c1e071d10d?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXIIN6KSJOFJ4YTNE%2F20200626%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20200626T143210Z&X-Amz-Expires=900&X-Amz-Signature=e88b2f1ece827c35bf2a7faf228851e2312beaeafabc95132537b6a5d206ada4&X-Amz-SignedHeaders=host",
                "doctype": "BNSLTR",
                "owner_role": "swan",
                "content_type": "application/pdf",
                "filename": "dummyfile",
                "path": "2020/Swan",
                "tags": "address",
                "nic": "A180181300165G"
              },
              {
                "uuid": "f4cb68b0-b25f-11ea-bb61-b3c1e071d10d",
                "download_url": "https://swan-kyc.s3.us-east-2.amazonaws.com/BNSLTR/18-01-81/A180181300165G/f4cb68b0-b25f-11ea-bb61-b3c1e071d10d?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXIIN6KSJOFJ4YTNE%2F20200626%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20200626T143210Z&X-Amz-Expires=900&X-Amz-Signature=e4aee1e6873e60d0764bbe5634777c0a55152d43e7f72b51c5ff19aa48933a10&X-Amz-SignedHeaders=host",
                "doctype": "BNSLTR",
                "owner_role": "swan",
                "content_type": "application/pdf",
                "filename": "F:\\Shares\\kemb\\Digital_scaleofcosts_brochure",
                "path": "2020/Swan",
                "tags": "address",
                "nic": "A180181300165G"
              },
              {
                "uuid": "6d151740-b2cd-11ea-bb61-b3c1e071d10d",
                "download_url": "https://swan-kyc.s3.us-east-2.amazonaws.com/BNSLTR/18-01-81/A180181300165G/6d151740-b2cd-11ea-bb61-b3c1e071d10d?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXIIN6KSJOFJ4YTNE%2F20200626%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20200626T143210Z&X-Amz-Expires=900&X-Amz-Signature=323b0a5986ab73013b09a233028d2062520241f87b9b2b2dd1c1adf5cb3c539e&X-Amz-SignedHeaders=host",
                "doctype": "BNSLTR",
                "owner_role": "swan",
                "content_type": "application/pdf",
                "filename": "DPA",
                "path": "2020/Swan",
                "tags": "address",
                "nic": "A180181300165G"
              },
              {
                "uuid": "dbc65940-b464-11ea-bb61-b3c1e071d10d",
                "download_url": "https://swan-kyc.s3.us-east-2.amazonaws.com/BNSLTR/18-01-81/A180181300165G/dbc65940-b464-11ea-bb61-b3c1e071d10d?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXIIN6KSJOFJ4YTNE%2F20200626%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20200626T143211Z&X-Amz-Expires=900&X-Amz-Signature=ec9d3d9eb5bfb610e3ad270018feb1e553232d24b7e6480cffee6b249f6ae379&X-Amz-SignedHeaders=host",
                "doctype": "BNSLTR",
                "owner_role": "swan",
                "content_type": "application/pdf",
                "filename": "s3documentation",
                "path": "2020/Swan",
                "tags": "address",
                "nic": "A180181300165G"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    doctype: "TEST",
    "files": [
      {
        "uuid": "40105ef0-b131-11ea-aa91-f77318a17d37",
        "download_url": "https://swan-kyc.s3.us-east-2.amazonaws.com/BNSLTR/18-01-81/A180181300165G/40105ef0-b131-11ea-aa91-f77318a17d37?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXIIN6KSJOFJ4YTNE%2F20200624%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20200624T133821Z&X-Amz-Expires=900&X-Amz-Signature=ba90ca0c29aef1b1e545e2db35e16c2839690efb1e68dbddc20792302b92c85c&X-Amz-SignedHeaders=host",
        "doctype": "BNSLTR",
        "owner_role": "swan",
        "content_type": "application/pdf",
        "filename": "loansdept",
        "path": "2020/Swan",
        "tags": [
          "address",
          "correspondence"
        ],
        "nic": "A180181300165G"
      },
    ]
  }
]


class Structure extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    fstruct: [],
    fileSelected: null
  }

  componentDidMount() {
    if (!this.props.fstruct.length) {
      this.setState({ fstruct: this.props.fstruct });
    }
  }

  selectFile(file) {
    // console.log("select file - ", file);
    this.setState({ selected_file: file });
    this.props.setFileSelected(file);
  }

  render() {
    return (
      <div className="structure" >
        <FolderStructure fstruct={this.props.fstruct} fileSelected={this.state.selected_file} changeFile={(file) => this.selectFile(file)}></FolderStructure>
      </div>
    )
  }
}

const mapStateToProps = (data) => {
  const { structure_reducer } = (data)
  return { structure_reducer };
}

export default connect(mapStateToProps, { setFileSelected })(Structure);
