import React from 'react';
import SWANLOGO from '../assets/swan-drive-logo.png';

function Header() {
  return (
    <div className="header-wrapper">
      <header className="header">
        <img src={SWANLOGO} className="header-logo" alt="logo" />
        <a className="header-text">
          Drive
        </a>
      </header>
    </div>
  );
}

export default Header;
