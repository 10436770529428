import React, { Component } from 'react';
import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter
} from "react-router-dom";
import Login from "./userinterface/Login";
// import 'bootstrap/dist/css/bootstrap.min.css';
import './css/font.css';
import './App.css';
import './css/global.css';
import SuccessSnackbar from './components/SuccessSnackbar';
import Drivewrap from './userinterface/Drivewrap';

export default class App extends Component {
  render() {
    const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));
    return (
      <Provider store={store}>
        <SuccessSnackbar />
        <HashRouter>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route  path="/drive">
              <Drivewrap />
            </Route>
          </Switch>
        </HashRouter>

      </Provider>
    );
  }
}
