import {
    LOGINWITHNIC_PAYLOAD,
    OTP_PAYLOAD,
    CREATE_USERPROFILE,
    GETLOANBREAKDETAILS
} from '../constants'
import CryptoJS from 'crypto-js';
import { config } from "../config"

const API_URL = config.DRIVE_API_URL;

const AES_ENCRYPT = (val) => {
    var key = CryptoJS.enc.Utf8.parse(config.HANDSHAKEKEY); // HANDSHAKEKEY
    var iv = CryptoJS.enc.Utf8.parse(config.HANDSHAKEKEY); //HANDSHAKEKEY

    var encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(val), key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

    return encryptedlogin;

}

export const loginwithNic = (nicvalue) => {
    return (dispatch) => {
        const body_tosend = { nic: AES_ENCRYPT(nicvalue).toString() }
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body_tosend)
        }
        fetch(`${API_URL}/api/wsloginwithnic`,
            options
        ).then(function (res) {
            return res.json();
        }).then(function (responseURL) {

            dispatch({
                type: LOGINWITHNIC_PAYLOAD,
                payload: responseURL
            })

            return responseURL;
        }).catch(function (err) {
            console.log(err)
        });
    }
}

export const validateOTP = (nicvalue, otpvalue) => {
    return (dispatch) => {
        const body_tosend = { nic: AES_ENCRYPT(nicvalue).toString(), otp: AES_ENCRYPT(otpvalue).toString() };
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body_tosend)
        }
        fetch(`${API_URL}/api/wsverifyotp`,
            options
        ).then(function (res) {
            return res.json();
        })
            .then(function (responseURL) {

                dispatch({
                    type: OTP_PAYLOAD,
                    payload: responseURL
                })

                return responseURL;
            })
            .catch(function (err) {
                console.log(err)
            });
    }
}

export const getSignToken = (nic) => {
    return (dispatch) => {
        const body_tosend = { nic: nic };
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body_tosend)
        }
        fetch(`${API_URL}/api/getsigntoken`,
            options
        ).then(function (res) {
            return res.json();
        })
            .then(function (responseURL) {
                dispatch({
                    type: "GET_TOKEN",
                    payload: responseURL
                })
                return responseURL;
            })
            .catch(function (err) {
                console.log(err)
            });
    }
}

export const createuserprofile = (userprofile) => {
    return (dispatch) => {
        var body_tosend = {
            name: AES_ENCRYPT(userprofile.name).toString(),
            surname: AES_ENCRYPT(userprofile.surname).toString(),
            phonenumber: AES_ENCRYPT(userprofile.phonenumber).toString(),
            email: AES_ENCRYPT(userprofile.emailaddress).toString(),
            nic: AES_ENCRYPT(userprofile.nic).toString()
        };
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body_tosend)
        }
        fetch(`${API_URL}/api/createuserprofile`,
            options
        ).then(function (res) {
            return res.json();
        })
            .then(function (responseURL) {
                dispatch({
                    type: CREATE_USERPROFILE,
                    payload: responseURL
                })

                return responseURL;
            })
            .catch(function (err) {
                console.log(err)
            });
    }
}


export const GetLoanBreakDtls = (nicvalue) => {
    return (dispatch) => {
        var body_tosend = { nic: AES_ENCRYPT(nicvalue).toString() }
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body_tosend)
        }
        fetch(`${API_URL}/api/getloandetails`,
            options
        ).then(function (res) {
            return res.json();
        })
            .then(function (responseURL) {
                dispatch({
                    type: GETLOANBREAKDETAILS,
                    payload: responseURL
                })

                return responseURL;
            })
            .catch(function (err) {
                console.log(err)
            });
    }
}

export const GetKycProfileData = (nicvalue) => {
    return (dispatch) => {
        var body_tosend = { NIC: AES_ENCRYPT(nicvalue).toString() }
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body_tosend)
        }
        fetch(`${API_URL}/api/getkycdetails`,
            options
        ).then(function (res) {
            return res.json();
        })
            .then(function (responseURL) {
                dispatch({
                    type: "GET_CLIENT_PROFILE_DATA",
                    payload: responseURL
                })

                return responseURL;
            })
            .catch(function (err) {
                console.log(err)
            });
    }
}


export const verifyKey = (keyparam) => {
    return (dispatch) => {
        var body_tosend = { KEYVALUE: keyparam }
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body_tosend)
        }
        fetch(`${API_URL}/api/verifykey`,
            options
        ).then(function (res) {
            return res.json();
        })
            .then(function (responseURL) {

                dispatch({
                    type: GETLOANBREAKDETAILS,
                    payload: responseURL
                })


                return responseURL;
            })
            .catch(function (err) {
                console.log(err)
            });
    }
}

export const verifyToken = (token) => {
    return (dispatch) => {
        var body_tosend = { token: token }
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body_tosend)
        }
        fetch(`${API_URL}/api/verifytoken`,
            options
        ).then(function (res) {
            return res.json();
        })
            .then(function (responseURL) {
                dispatch({
                    type: "GET_TOKEN",
                    payload: responseURL
                })
                return responseURL;
            })
            .catch(function (err) {
                console.log(err)
            });
    }
}
