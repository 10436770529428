const INITIAL_STATE = {};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case "GET_STRUCTURE_SUCCESS":
      return { structure: action.payload };
    case "SELECT_FILE":
      return { selected_file: action.payload }
    case "STRUCT_CLEAR":
      return INITIAL_STATE
    default:
      return state;
  }
}
