import React, { Component } from 'react';
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { Icon } from "@material-ui/core";
import { clearSnackbar } from "../actions/snackbarActions";
import { connect } from 'react-redux';

class SuccessSnackbar extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    successSnackbarMessage: '',
    successSnackbarOpen: false
  }

  componentWillReceiveProps(nextProps, nextContext) {
    console.log("SuccessSnackbar - ", nextProps.snackbarreducer);
    this.setState({ ...nextProps.snackbarreducer });
  }

  handleClose() {
    this.props.clearSnackbar();
  }

  render() {
    return (
      <div>
        <Snackbar classes={{root: 'snackbar-wrp'}}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.successSnackbarOpen}
          autoHideDuration={5000}
          onClose={() => this.handleClose()}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar">
              {this.state.successSnackbarMessage}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={() => this.handleClose()}
            >
              <Icon>close</Icon>
            </IconButton>
          ]}
        />
      </div>
    )
  }
}
const mapStateToProps = (data) => {
  const { snackbarreducer } = (data)
  return { snackbarreducer };
}

export default connect(mapStateToProps, { clearSnackbar })(SuccessSnackbar);