import React, { useState } from 'react';

function FolderTitle(props) {
  let { target, title } = { ...props };
  const [isFolderOpen, setOpen] = useState(false);
  const onToggleFolder = (e) => {
    if (e.currentTarget.ariaExpanded && e.currentTarget.ariaExpanded === "false") {
      setOpen(false)
    }
    else {
      setOpen(true)
    }
  }
  return (
    <a className="panel-title" data-toggle="collapse" onClick={onToggleFolder}
      data-target={'#' + target}
    >
      {isFolderOpen ? <i className="far fa-fw fa-lg fa-folder-open mr-2"></i> : <i className="far fa-fw fa-lg fa-folder mr-2"></i>}
      {title}
    </a>
  );
}

export default FolderTitle;
