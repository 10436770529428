import React, { useState } from 'react';
import FileViewer from './FileViewer';
import '../css/mobiledrive.css';
import SWANLOGO from '../assets/swan-drive-logo.png';
import CategoryContainer from '../components/Mobile/Category/Category';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function MobileDrive(props) {
  const { files, selected_file, changeFile } = { ...props }
  const dispatch = useDispatch();

  const [isLogin, setLogin] = useState(true);

  const handleLogout = () => {
    setLogin(false);
    dispatch({type:"AUTH_CLEAR"});
    dispatch({type:"FILES_CLEAR"});
    dispatch({type:"STRUCT_CLEAR"});
  }

  return (
    <div className="mobile-wrapper">
      <div className="mobile-header ">
        {!selected_file &&
          <div className="container appbar-wrapper">
            <div className="logo-wrapper">
              <img src={SWANLOGO} className="header-logo" alt="logo" />
            </div>
            <div>
              <button className="btn btn-logout" onClick={() => handleLogout()}>LOG OUT</button>
            </div>
          </div>
        }
        <div className="header-wrapper">
          {selected_file &&
            <div className="container file-selected">
              <div className="back-nav" onClick={() => changeFile(null)}>
                <i className="fas fa-fw fa-lg fa-chevron-left"></i>
              </div>
              <div className="header-title">
                {selected_file.metadata.filename}
              </div>
            </div>
          }
          {!selected_file &&
            <div className="container">
              <div className="header-title">
                My Documents
              </div>
            </div>
          }
        </div>

      </div>

      <div className="mobile-content">
        {!isLogin &&
          <Redirect
            to={{
              pathname: "/"
            }}
          />
        }
        {selected_file &&
          <div className="container">
            <FileViewer fileData={selected_file}></FileViewer>
          </div>
        }
        {!selected_file &&
          <div className="container">
            <CategoryContainer
              files={files}
              selected_file={selected_file}
              changeFile={changeFile} />
          </div>
        }
      </div>
    </div>
  )

}

export default MobileDrive;
