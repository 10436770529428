// PROD - https://secure-drive-api.swanforlife.com
// BETA - https://secure-drive-api-beta.idin.tech
// DEV - https://swanforlife-drive-s3api.idin.tech

const config_env = {
  default: {
    DRIVE_API_URL: "https://dev-drive-api.swanforlife.link",
    HANDSHAKEKEY: "5B1C74B311954830"
  },
  dev: {
    DRIVE_API_URL: "https://dev-drive-api.swanforlife.link",
    HANDSHAKEKEY: "5B1C74B311954830"
  },
  beta: {
    DRIVE_API_URL: "https://secure-drive-api-beta.idin.tech",
    HANDSHAKEKEY: "5B1C74B311954830"
  },
  prod: {
    DRIVE_API_URL: "https://secure-drive-api.swanforlife.com",
    HANDSHAKEKEY: "5B1C74B311954830"
  }
}

// Default to dev if not set
const configVal = (process.env.REACT_APP_STAGE && config_env[process.env.REACT_APP_STAGE])
  ? config_env[process.env.REACT_APP_STAGE]
  : config_env.default;

export const config = {
  // Add common config values here
  ...configVal
};