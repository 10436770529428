import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class AlertDialog extends Component {

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={() => this.props.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{this.props.contentText}</DialogTitle>
        <DialogActions>
          <Button onClick={() => this.props.handleClose()} color="primary" autoFocus>
            Close
              </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
