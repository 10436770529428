import React from 'react';

function FileItem(props) {
  let { file, isActive, onSelectFile } = { ...props };

  const getFileIcon = (type) => {
    if (type === "application/pdf") {
      return <i className="far fa-fw fa-lg fa-file-pdf mr-2"></i>
    }
    else {
      return <i className="far fa-fw fa-lg fa-file-image mr-2"></i>
    }
  }
  return (
    <li className={isActive ? 'list-group-item file-item active' : 'list-group-item file-item'}
      onClick={() => onSelectFile(file)}>
      {getFileIcon(file.metadata.content_type)}
      {file.metadata.filename}
    </li>
  );
}

export default FileItem;
