import { config } from "../config"
export const getFolderStructure = (nic, token) => {
  return (dispatch) => {
    let body_tosend = {
      nic,
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': token
      },
      body: JSON.stringify(body_tosend)
    }
    fetch(`${config.DRIVE_API_URL}/storage/getfolderstructure`, options)
      .then(res => res.json())
      .then(
        result => {
          if (result.response.includes("TOKEN")) {
            dispatch({
              type: "GET_TOKEN",
              payload: result
            })
          }
          else {
            dispatch({
              type: "GET_STRUCTURE_SUCCESS",
              payload: result
            })
          }

        },
        error => {
          console.log("Error getFolderStructure - ", error);
        })
  }
};

export const getFiles = (nic, token) => {
  return (dispatch) => {
    let body_tosend = {
      nic,
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': token
      },
      body: JSON.stringify(body_tosend)
    }
    fetch(`${config.DRIVE_API_URL}/storage/getfiles`, options)
      .then(res => res.json())
      .then(
        result => {
          if (result.response.includes("TOKEN") || result.response.includes("INVALID")) {
            dispatch({
              type: "GET_FILES_ERROR",
              payload: { message: result.error.message }
            })
          }
          else {
            // let files = result.files.filter(item => item.metadata.doctype === "BNSLTR");
            dispatch({
              type: "GET_FILES",
              payload: { files: result.files }
            })
          }
        },
        error => {
          console.log("Error getFiles - ", error);
        })
  }
};

export const getMySwanAppDocumentsStructure = (nic, token) => {
  return (dispatch) => {
    let body_tosend = {}
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': token
      },
      body: JSON.stringify(body_tosend)
    }
    fetch(`${config.DRIVE_API_URL}/storage/get-document-structure`, options)
      .then(res => res.json())
      .then(
        result => {
          console.log("getMySwanAppDocumentsStructure: ", result)
          if (result.response === "VALID") {
            dispatch({
              type: "SET_DOC_STRUCTURES",
              payload: result?.data?.structure || []
            })
          }
        },
        error => {
          console.log("Error getMySwanAppDocumentsStructure - ", error);
        })
  }
};

export const setFileViewed = (fileUuid, token) => {
  return (dispatch) => {
    let body_tosend = {
      uuid: fileUuid
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': token
      },
      body: JSON.stringify(body_tosend)
    }
    fetch(`${config.DRIVE_API_URL}/storage/files/viewed`, options)
      .then(res => res.json())
      .then(
        result => {
          if (result.response.includes("TOKEN") || result.response.includes("INVALID")) {
            dispatch({
              type: "GET_FILES_ERROR",
              payload: { message: result.error.message }
            })
          }
          else {
            dispatch({
              type: "FILE_UPDATED",
              payload: { file: result.file }
            })
          }
        },
        error => {
          console.log("Error setFileViewed - ", error);
        })
  }
}

export const getFileLink = (uuid, token) => {
  return new Promise((resolve, reject) => {
    let body_tosend = {
      uuid: uuid
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': token
      },
      body: JSON.stringify(body_tosend)
    }
    fetch(`${config.DRIVE_API_URL}/storage/files/get-link`, options)
      .then(res => res.json())
      .then(
        result => {
          if (result.response.includes("TOKEN") || result.response.includes("INVALID")) {
            reject(result.error.message)
          }
          else {
            resolve(result.link)
          }
        },
        error => {
          console.log("Error getFileLink - ", error);
        })
  })
}

export const setFileSelected = (file) => {
  return (dispatch) => {
    dispatch({ type: "SELECT_FILE", payload: file })
  }
};

export const emulateVerifyToken = () => {
  return (dispatch) => {
    dispatch({
      type: "CUSTOMER_TOKEN",
      payload: { response: "TOKEN_VALID", nic: "A180181300165G", token: "test" }
    })
  }

};