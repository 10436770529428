import React from 'react';
import FileItem from './FileItem';

function FilesList(props) {
  let { files, fileSelected, changeFile } = { ...props };
  // console.log("files - ", files);
  const isFileActive = (file) => {
    if (fileSelected && fileSelected.uuid === file.uuid) {
      return true;
    }
    return false;
  }
  return (
    <ul className="list-group files-list">
      {files && files.map((file, index) =>
        <FileItem key={index} file={file} isActive={isFileActive(file)} onSelectFile={changeFile} ></FileItem>
      )}
    </ul>
  );
}

export default FilesList;
