import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner'

export default class Loader extends Component {

  render() {
    return (
      <div className="loader-wrapper">
        <Spinner animation="border" variant="primary" />
        {/* <div className="card-body loader-demo loader-demo-sk d-flex align-items-center justify-content-center">
          <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1"></div>
            <div className="sk-cube sk-cube2"></div>
            <div className="sk-cube sk-cube3"></div>
            <div className="sk-cube sk-cube4"></div>
            <div className="sk-cube sk-cube5"></div>
            <div className="sk-cube sk-cube6"></div>
            <div className="sk-cube sk-cube7"></div>
            <div className="sk-cube sk-cube8"></div>
            <div className="sk-cube sk-cube9"></div>
          </div>
        </div> */}
      </div>
    );
  }
}
