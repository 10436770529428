import React from 'react';
import FilesList from './FilesList';
import FolderTitle from './FolderTitle';

function FolderStructure(props) {
  let { fstruct, fileSelected, changeFile } = { ...props };
  // console.log(fstruct);

  // let accordionId = "accordion-" + Math.random().toString(36).substring(7);

  let panels = fstruct.map((obj, index) => {
    let target = 'collapse' + Math.random().toString(36).substring(7);
    // let labelledby = 'heading-' + Math.random().toString(36).substring(7);

    return (
      <div key={index} className="panel panel-default">
        <div className="panel-heading">
          <FolderTitle target={target} title={obj.doctype || obj.fldname}></FolderTitle>
        </div>
        <div id={target}
          className="panel-collapse collapse">
          {
            obj.files &&
            <FilesList files={obj.files} fileSelected={fileSelected} changeFile={changeFile}></FilesList>
          }
          {
            obj.fld &&
            <FolderStructure fstruct={obj.fld} fileSelected={fileSelected} changeFile={changeFile}></FolderStructure>
          }
        </div>
      </div>
    )
  });


  return (
    <div className="panel-group doctype-panels" >
      {panels}
    </div>
  );
}

export default FolderStructure;
