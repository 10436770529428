import React, { Component } from 'react';
import '../css/drive.css';
import { connect } from 'react-redux';
import { verifyToken } from '../actions';
import { showSnackbar } from '../actions/snackbarActions';
import { getFolderStructure, emulateVerifyToken, getFiles, setFileViewed, getMySwanAppDocumentsStructure } from '../actions/folderStructureAction';
import { Loader } from '../components';
import { Redirect } from "react-router-dom";
import {
  isBrowser,
  isMobile
} from "react-device-detect";
import MobileDrive from './MobileDrive';
import DesktopDrive from './DesktopDrive';


class Drivewrap extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    nic: "",
    authenticationCheck: true,

    // alert dialog
    isAlertOpen: false,
    alertText: "",

    // loading component display
    loading: false,

    verified_token: false,
    token: "",

    fstruct: [],
    files: [],
    grouped_files: {},
    selected_file: null

  }

  componentDidMount() {

    // this.props.GetLoanBreakDtls("A180181300165G");
    //this.props.GetLoanBreakDtls("R2606893827651");
    /**
     NICS: A180181300165G,M1112612200096, R1110824602101,
     A180181300165G: husband wife, avec guarantor
  
     */
    if (this.props.authreducer) {
      if (this.props.authreducer.verify_token) {
        console.log("AUTH OK");
        this.showLoading();
        this.setState({
          verified_token: true,
          token: this.props.authreducer.verify_token.token,
          nic: this.props.authreducer.verify_token.nic
        });
        // if (isBrowser) {
        //   this.props.getFolderStructure(this.props.authreducer.verify_token.nic, this.props.authreducer.verify_token.token);
        // }
        // else if (isMobile) {
        //   this.props.getFiles(this.props.authreducer.verify_token.nic, this.props.authreducer.verify_token.token);
        // }
        this.props.getFiles(this.props.authreducer.verify_token.nic, this.props.authreducer.verify_token.token);
        //It means the user has used loginwithnic and also wsverifyotp and both were successful
      } else {
        console.log("TRYING TO DETECT A KEY");
        const testURL = window.location.href;
        if (testURL.indexOf("?access_token=") !== -1) {
          this.showLoading();
          var access_token = testURL.split("?access_token=")[1];
          console.log("TOKEN DETECTED");
          this.props.verifyToken(access_token);

        } else {
          //NO OP
          this.props.showSnackbar("Token not found!");
          // this.props.emulateVerifyToken();
          console.log("TOKEN DETECT FAILED");
          console.log("ABORT")
          this.setState({ authenticationCheck: false });
          //If no KEY and also no authentication force to loginwithNic screen
        }
      }
    }

  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.authreducer.verify_token && !this.state.verified_token) {
      if (nextProps.authreducer.verify_token.response === "TOKEN_VALID") {
        this.changeState({
          verified_token: true,
          token: nextProps.authreducer.verify_token.token,
          nic: nextProps.authreducer.verify_token.nic
        })
        this.props.getFiles(nextProps.authreducer.verify_token.nic, nextProps.authreducer.verify_token.token);
        this.props.getMySwanAppDocumentsStructure(nextProps.authreducer.verify_token.nic, nextProps.authreducer.verify_token.token);
        // if (isBrowser) {
        //   // this.props.getFiles(nextProps.authreducer.verify_token.nic, nextProps.authreducer.verify_token.token);
        //   this.props.getFolderStructure(nextProps.authreducer.verify_token.nic, nextProps.authreducer.verify_token.token);
        // }
        // else if (isMobile) {
        //   this.props.getFiles(nextProps.authreducer.verify_token.nic, nextProps.authreducer.verify_token.token);
        // }
        // this.props.getFolderStructure(nextProps.authreducer.verify_token.nic, nextProps.authreducer.verify_token.token);
        // this.showLoading();
        // this.hideLoading();
      }
    }

    if (nextProps.authreducer.verify_token &&
      (nextProps.authreducer.verify_token.response === "TOKEN_FAILED" ||
        nextProps.authreducer.verify_token.response === "TOKEN_EXPIRED")) {

      if (nextProps.authreducer.verify_token.response === "TOKEN_FAILED") {
        console.log("TOKEN_FAILED! - ", nextProps.authreducer.verify_token.error.message);
        this.hideLoading();
        this.props.showSnackbar(nextProps.authreducer.verify_token.error.message);
        this.changeState({ authenticationCheck: false });
      }
      else if (nextProps.authreducer.verify_token.response === "TOKEN_EXPIRED") {
        console.log("TOKEN_EXPIRED! - ", nextProps.authreducer.verify_token.error.message);
        this.hideLoading();
        this.props.showSnackbar(nextProps.authreducer.verify_token.error.message);
        this.changeState({ authenticationCheck: false });
      }
    }

    if (nextProps.structure_reducer.structure) {
      this.hideLoading();
      if (nextProps.structure_reducer.structure.response === "VALID") {
        console.log("structure_reducer - ", nextProps.structure_reducer);
        this.changeState({ fstruct: nextProps.structure_reducer.structure.fstruct });
      }
      else if (nextProps.structure_reducer.structure.response === "INVALID") {
        this.props.showSnackbar(nextProps.structure_reducer.structure.error.message);
      }
    }

    if (nextProps.structure_reducer.selected_file) {
      this.changeState({ selected_file: nextProps.structure_reducer.selected_file });
    }
    if (nextProps.file_reducer) {
      if (nextProps.file_reducer.loaded) {
        let files_group = nextProps.file_reducer.files.reduce(function (r, a) {
          r[a.metadata.doctype] = r[a.metadata.doctype] || [];
          r[a.metadata.doctype].push(a);
          return r;
        }, {});
        // console.log('files_group - ', files_group);
        this.changeState({ files: nextProps.file_reducer.files, grouped_files: files_group, loading: false });
      }
      else if (!nextProps.file_reducer.loaded && nextProps.file_reducer.errMessage) {
        this.props.showSnackbar(nextProps.file_reducer.errMessage);
        this.hideLoading();
      }

    }
  }

  changeState(newState) {
    console.log("Change State");
    this.setState(newState);
  }

  showLoading() {
    this.changeState({ loading: true })
  }

  hideLoading() {
    this.changeState({ loading: false })
  }

  alertDialogClose() {
    this.setState({ isAlertOpen: false, alertText: "" })
  }

  alertDialogOpen(text) {
    this.setState({ isAlertOpen: true, alertText: text })
  }

  changeFile(file) {
    if (file && !file.viewed) {
      this.props.setFileViewed(file.uuid, this.state.token);
    }
    this.changeState({ selected_file: file });
  }



  render() {
    let drive;
    drive = <MobileDrive files={this.state.grouped_files} selected_file={this.state.selected_file} changeFile={(file) => { this.changeFile(file) }}></MobileDrive>
    // if (isMobile) {
    //   drive = <MobileDrive files={this.state.grouped_files} selected_file={this.state.selected_file} changeFile={(file) => { this.changeFile(file) }}></MobileDrive>
    // }
    // else if (isBrowser) {
    //   drive = <DesktopDrive fstruct={this.state.fstruct} selected_file={this.state.selected_file}></DesktopDrive>
    // }
    return (
      <div className="container-fluid">
        {!this.state.authenticationCheck &&
          <Redirect
            to={{
              pathname: "/"
            }}
          />
        }
        {
          //   this.state.isAlertOpen &&
          //   <AlertDialog isOpen={this.state.isAlertOpen}
          //     contentText={this.state.alertText}
          //     handleClose={() => this.alertDialogClose()}>
          //   </AlertDialog>
        }
        {
          this.state.loading &&
          <Loader></Loader>
        }

        {this.state.authenticationCheck &&
          drive
        }
      </div>
    )
  }
}

const mapStateToProps = (data) => {
  const { authreducer, structure_reducer, file_reducer } = (data)
  return { authreducer, structure_reducer, file_reducer };
}

export default connect(mapStateToProps, { verifyToken, showSnackbar, getFolderStructure, getFiles, emulateVerifyToken, setFileViewed, getMySwanAppDocumentsStructure })(Drivewrap);

