import {LOGINWITHNIC_PAYLOAD, OTP_PAYLOAD,CREATE_USERPROFILE,GETLOANBREAKDETAILS} from '../constants';
const INITIAL_STATE = {};
export default (state = INITIAL_STATE,action)=>{
    switch(action.type){

        case LOGINWITHNIC_PAYLOAD:
            return {nic:action.payload};
        case OTP_PAYLOAD:
            return {otp:action.payload};
        case CREATE_USERPROFILE:
            return {userprofile:action.payload}
        case GETLOANBREAKDETAILS:
            return {getloanbreakdetails:action.payload};
        case "GET_TOKEN":
            return {verify_token: action.payload};
        case "CUSTOMER_TOKEN":
            return {customer_token: action.payload};
        case "VERIFIED_TOKEN":
            return {token: action.payload};
        case "FAILED_TOKEN":
            return {token: action.payload};
        case "GET_CLIENT_PROFILE_DATA":
            return {getclientprofile: action.payload};
        case "AUTH_CLEAR":
            return INITIAL_STATE
        default:
            return state;
    }
}
