const INITIAL_STATE = {
  loaded: false,
  files: [],
  selectedFile: null,
  errMessage: null,
  docStructures: []
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case "SELECT_FILE":
      return Object.assign({}, state, { selectedFile: action.payload.file })
    case "GET_FILES":
      return Object.assign({}, state, { files: action.payload.files, loaded: true, errMessage: null })
    case "GET_FILES_ERROR":
      return Object.assign({}, state, { errMessage: action.payload.message, loaded: false })
    case "SET_DOC_STRUCTURES":
      return Object.assign({}, state, { docStructures: action.payload })
    case "FILE_UPDATED":
      let file = action.payload.file;
      let files = state.files;
      let newFiles = files.map(item => {
        if (item.uuid === file.uuid) {
          item = { ...file };
        }
        return item;
      })
      return Object.assign({}, state, { files: newFiles })

    case "FILES_CLEAR":
      return Object.assign({}, state, INITIAL_STATE)
    default:
      return state;
  }
}
