import React, { useEffect, useState } from 'react';
import styles from './category.module.css';
import MobileFilesList from '../FilesList/FilesList';
import { useSelector } from 'react-redux';

export const FileCategory = [
  {
    label: "Letters",
    subcategory: [
      {
        label: "With-Profits Annual Statements",
        category_type: "BNSLTR"
      },
      {
        label: "Annual Unit Link Statement",
        category_type: "UNTLNK"
      }
      // {
      //   label: "Statement of account",
      //   category_type: "SGSOA"
      // }
    ]
  }
  // {
  //   label: 'KYC',
  //   subcategory: []
  // }
];

function CategoryContainer(props) {
  const { files, selected_file, changeFile } = { ...props }
  const categoryList = useSelector(state => state.file_reducer.docStructures || [])

  const [augmentedDocumentsList, setAugmentedDocumentsList] = useState([]);

  useEffect(() => {
    if (categoryList.length > 0 && Object.keys(files)?.length > 0) {
      let doctypesKeys = Object.keys(files);
      let augmentedList = [];
      for (let category of categoryList) {
        let newSection = { ...category }
        let subsections = category.subs.map((it) => it.doctype)
        if (subsections?.some((el) => doctypesKeys.includes(el))) {
          newSection.isVisible = true
          for (let subCategory of newSection.subs) {
            if (files[subCategory.doctype]) {
              subCategory.files = files[subCategory.doctype]
              subCategory.isVisible = true
            } else {
              subCategory.isVisible = false
            }
          }
        } else {
          newSection.isVisible = false
        }
        augmentedList.push(newSection)
      }
      setAugmentedDocumentsList(augmentedList)
    }
  }, [categoryList, files])

  return (
    augmentedDocumentsList.map((category, index) => {
      if (category.isVisible) {
        return (
          <div className={styles.section} key={index}>
            <div className={styles.section_title}>
              <span>{category.label}</span>
            </div>
            {
              category.subs.map(subctgr => {
                if (subctgr.isVisible) {
                  return (
                    <div key={Math.random()} className="section-content">
                      <div className={`row align-items-center ${styles.type_title}`}>
                        <div className="col ">
                          <span>{subctgr.label}</span>
                        </div>
                      </div>
                      <div className="row">
                        <MobileFilesList files={subctgr.files} selected_file={selected_file} changeFile={changeFile}></MobileFilesList>
                      </div>
                    </div>
                  )
                }
              })
            }
          </div>
        )
      }
    })
  )
}

export default CategoryContainer;
