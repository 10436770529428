import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class ConfirmDeleteDialog extends Component {

  constructor(props) {
    super(props);

  }
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={() => this.props.handleClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this file?"}</DialogTitle>

          <DialogActions>
            <Button onClick={() => this.props.handleClose(false)} color="primary">
              No
            </Button>
            <Button onClick={() => this.props.handleClose(true)} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

}